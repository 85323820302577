import supportedLocales from "./supportedLocales";

export const localeKey = "locale";

const getLocaleFromBrowser = () => {
  const languages: string[] = [];
  if (navigator.language) languages.push(navigator.language);
  if (navigator.languages) languages.push(...navigator.languages);

  const locales = languages
    .map(l => l.substr(0, 2))
    .filter(l => supportedLocales.indexOf(l) >= 0);

  return locales.length ? locales[0] : null;
};

export default getLocaleFromBrowser;
