import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import useTitle from "../../hooks/useTitle";

interface ServiceProps {
  className?: string;
}

const Service = ({ className }: ServiceProps) => {
  const title = useTitle({
    defaultMessage: "Service",
    id: "menu.service",
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container className={className}>
      <Row>
        <Col>
          <h1>
            <FormattedMessage defaultMessage="Service" id="service" />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            <FormattedMessage
              defaultMessage="I offer varying works, including preservation, corrosion protection of private or sacral buildings, offices and sport or recreation facilities. My work is based on mountaineer technique and equipment. I possess all required skills and  qualifications!"
              id="service.main"
            />
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <h2>
              <FormattedMessage
                defaultMessage="Our services"
                id="service.list"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  defaultMessage="Facade painting"
                  id="service.list.01"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Felling and maintenance of trees"
                  id="service.list.02"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Installation of billboards"
                  id="service.list.03"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Roof painting"
                  id="service.list.04"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Roof cleaning"
                  id="service.list.05"
                />
              </li>
              <li>
                <FormattedMessage
                  id="service.list.06"
                  defaultMessage="Snow removal from the roof (seasonal)"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Expert opinions"
                  id="service.list.07"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Photography documentations"
                  id="service.list.08"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Cleaning, reparation and painting of gutter"
                  id="service.list.09"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Hazards removal"
                  id="service.list.10"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Bird deterring"
                  id="service.list.11"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Interior painting"
                  id="service.list.12"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Others"
                  id="service.list.13"
                />
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6}>
          <div>
            <h2>
              <FormattedMessage
                defaultMessage="I guarantee"
                id="service.guarantee"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  defaultMessage="An individual approach to each client"
                  id="service.guarantee.01"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="The safety of the work"
                  id="service.guarantee.02"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Short deadline"
                  id="service.guarantee.03"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="The highest quality of our service"
                  id="service.guarantee.04"
                />
              </li>
              <li>
                <FormattedMessage
                  defaultMessage="Competitive prices"
                  id="service.guarantee.05"
                />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const StyledService = styled(Service)`
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding-left: 1.5em;
  }

  li:before {
    color: #afafaf;
    content: "\\ea10";
    display: inline-block;
    font-family: icomoon;
    margin-left: -1.3em;
    width: 1.8em;
  }
`;

export default StyledService;
