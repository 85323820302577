import React from "react";

import LinkToService from "./LinkToService";
import LinkToVideos from "./LinkToVideos";

interface LinkToProps {
  messageKey: string;
}

const LinkTo = ({ messageKey }: LinkToProps) => {
  return messageKey.endsWith("offer") ? (
    <LinkToService />
  ) : messageKey.endsWith("videos") ? (
    <LinkToVideos />
  ) : null;
};

export default LinkTo;
