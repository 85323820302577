import { Action } from "redux";

import {
  Action as SetLocaleAction,
  actionType as setLocaleActionType,
} from "../actions/setLocale";
import getLocaleFromBrowser from "../utils/getLocaleFromBrowser";

const defaultLocale = "pl";
const browserLocale = getLocaleFromBrowser();
const locale = browserLocale || defaultLocale;

const defaultState = {
  locale,
};

export interface State {
  locale: string;
}

const intl = (state = defaultState, action: Action): State => {
  switch (action.type) {
    case setLocaleActionType:
      return {
        ...state,
        locale: (action as SetLocaleAction).locale,
      };
    default:
      return state;
  }
};

export default intl;
