import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Lightbox from "react-image-lightbox";
import PhotoGallery, { PhotoProps } from "react-photo-gallery";

import { photos } from "./photos";
import useTitle from "../../hooks/useTitle";

const Gallery = () => {
  const title = useTitle({
    defaultMessage: "Gallery",
    id: "menu.photos",
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setPhotoIndex(index);
    setLightboxIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setPhotoIndex(0);
    setLightboxIsOpen(false);
  };

  const showPrevPhoto = () => {
    setPhotoIndex((photoIndex + photos.length - 1) % photos.length);
  };

  const showNextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % photos.length);
  };

  const galleryPhotos: Array<PhotoProps> = photos.map((f) => ({
    src: f.thumb,
    width: f.width,
    height: f.height,
  }));

  return (
    <Container>
      <Row>
        <Col>
          <h1>
            <FormattedMessage defaultMessage="Gallery" id="photos" />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <PhotoGallery photos={galleryPhotos} onClick={openLightbox} />
            {lightboxIsOpen && (
              <Lightbox
                mainSrc={photos[photoIndex].image}
                nextSrc={photos[(photoIndex + 1) % photos.length].image}
                prevSrc={
                  photos[(photoIndex + photos.length - 1) % photos.length].image
                }
                onCloseRequest={closeLightbox}
                onMovePrevRequest={showPrevPhoto}
                onMoveNextRequest={showNextPhoto}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Gallery;
