import throttle from "lodash.throttle";
import { createStore } from "redux";

import reducer, { State } from "../modules/reducers";

const wait = 1000;
const stateKey = "state";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(stateKey);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: State) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(stateKey, serializedState);
  } catch {}
};

const state = loadState();

const store = createStore(
  reducer,
  state,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, wait)
);

export default store;
