import { useState, useEffect } from "react";
import { useIntl, MessageDescriptor } from "react-intl";

const useTitle = (message: MessageDescriptor) => {
  const intl = useIntl();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const page = intl.formatMessage(message);
    const title = intl.formatMessage(
      {
        defaultMessage: "Alpinjob - {page}",
        id: "title"
      },
      {
        page
      }
    );

    setTitle(title);
  }, [intl, message]);

  return title;
};

export default useTitle;
