import React from "react";
import { FormattedDate } from "react-intl";
import styled from "styled-components";

interface VideoProps {
  className?: string;
  date: Date;
  id: string;
  title: string;
}

const Video = ({ className, date, id, title }: VideoProps) => (
  <div className={className}>
    <iframe
      height="200px"
      width="100%"
      title={title}
      src={`https://www.youtube.com/embed/${id}`}
      allowFullScreen={true}
    />
    <a href={`https://www.youtube.com/watch?v=${id}`}>{title}</a>
    <FormattedDate value={date} />
  </div>
);

const StyledVideo = styled(Video)`
  display: flex;
  flex-flow: column;
  margin-bottom: 18px;

  iframe {
    border: none;
  }

  a {
    font-weight: bold;
    font-size: 13pt;
    line-height: 1.25;
    margin-top: 12px;
  }

  span {
    color: var(--gray-600-color);
    font-size: 11pt;
    font-weight: bold;
    margin: 6px 0;
  }
`;

export default StyledVideo;
