import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import LinkTo from "./LinkTo";
import useTitle from "../../../hooks/useTitle";

const About = () => {
  const intl = useIntl();
  const title = useTitle({
    defaultMessage: "About",
    id: "menu.about",
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  const keys = Object.keys(intl.messages).filter((k) =>
    k.startsWith("about.line")
  );

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1>
              <FormattedMessage
                defaultMessage="Welcome to alpinjob.pl website"
                id="about"
              />
            </h1>
            {keys.map((k) => (
              <p key={k}>
                <FormattedMessage
                  defaultMessage={k}
                  id={k}
                  values={
                    k.endsWith("offer") || k.endsWith("videos")
                      ? {
                          link: <LinkTo messageKey={k} />,
                        }
                      : undefined
                  }
                />
              </p>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
