import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const LinkToVideos = () => (
  <Link to="/videos">
    <FormattedMessage defaultMessage="videos" id="about.videos_link" />
  </Link>
);

export default LinkToVideos;
