import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface ContactProps {
  className?: string;
}

const Contact = ({ className }: ContactProps) => (
  <div className={className}>
    <h5>
      <FormattedMessage defaultMessage="Contact" id="footer.contact" />
    </h5>
    <ul>
      <li>
        <div className="label">
          <i className="icon icon-phone-square" />
          <FormattedMessage defaultMessage="Phone" id="footer.contact.phone" />
        </div>
        <div className="value">666 143 953</div>
      </li>
      <li>
        <div className="label">
          <i className="icon icon-envelope" />
          <FormattedMessage defaultMessage="Email" id="footer.contact.email" />
        </div>
        <div className="value">
          <a href="mailto:biuro@alpinjob.pl?Subject=Alpinjob" target="_top">
            biuro@alpinjob.pl
          </a>
        </div>
      </li>
    </ul>
  </div>
);

const StyledContact = styled(Contact)`
  ul {
    list-style: none;

    li {
      .label {
        color: white;
        display: flex;
        align-items: center;

        i {
          display: block;
          width: 24px;
        }
      }

      .value {
        margin-top: -4px;
        padding-left: 24px;
      }
    }

    li + li {
      margin-top: 18px;
    }
  }
`;

export default StyledContact;
