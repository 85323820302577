import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface ContentProps {
  className?: string;
}

const Content = ({ className }: ContentProps) => (
  <div className={className}>
    <h5>
      <FormattedMessage defaultMessage="Content" id="footer.page_content" />
    </h5>
    <ul>
      <li>
        <Link to="/about">
          <FormattedMessage defaultMessage="About" id="menu.about" />
        </Link>
      </li>
      <li>
        <Link to="/service">
          <FormattedMessage defaultMessage="Service" id="menu.service" />
        </Link>
      </li>
      <li>
        <Link to="/gallery">
          <FormattedMessage defaultMessage="Gallery" id="menu.photos" />
        </Link>
      </li>
      <li>
        <Link to="/videos">
          <FormattedMessage defaultMessage="Videos" id="menu.videos" />
        </Link>
      </li>
    </ul>
  </div>
);

const StyledContent = styled(Content)`
  ul {
    list-style: none;

    li {
      padding-left: 1.5em;
    }

    li:before {
      content: "";
      display: inline-block;
      border-color: transparent white;
      border-style: solid;
      border-width: 0.28em 0 0.28em 0.4em;
      margin-bottom: 1px;
      margin-left: -1.3em;
      width: 1.8em;
    }
  }
`;

export default StyledContent;
