import React, { forwardRef, Props } from "react";
import { DropdownMenuProps } from "react-bootstrap/DropdownMenu";
import styled from "styled-components";

interface MenuProps extends Props<{}>, DropdownMenuProps {
  className?: string;
}

const Menu = forwardRef(({ className, children }: MenuProps, ref) => {
  return (
    <div className={className}>
      <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
    </div>
  );
});

const StyledMenu = styled(Menu)`
  border-radius: 0 !important;
  padding: 0 !important;

  ul {
    margin-bottom: 0;

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active,
      &:active {
        background-color: var(--accent-500-color);
      }
    }
  }
`;

export default StyledMenu;
