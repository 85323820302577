import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import IntlProvider from "./components/IntlProvider";
import Menu from "./components/Menu";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Service from "./pages/Service";
import Videos from "./pages/Videos";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "react-image-lightbox/style.css";
import "./theme.css";

const App = () => {
  return (
    <ReduxProvider store={store}>
      <IntlProvider>
        <HashRouter>
          <Menu />
          <Header />
          <Switch>
            <Route path="/about" component={About} exact />
            <Route path="/service" component={Service} exact />
            <Route path="/gallery" component={Gallery} exact />
            <Route path="/videos" component={Videos} exact />
            <Redirect to="/about" />
          </Switch>
          <Footer />
        </HashRouter>
      </IntlProvider>
    </ReduxProvider>
  );
};

export default App;
