export const photos = [
  {
    image: "/static/gallery/images/img004.jpg",
    thumb: "/static/gallery/thumbs/img004.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img005.jpg",
    thumb: "/static/gallery/thumbs/img005.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img007.jpg",
    thumb: "/static/gallery/thumbs/img007.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img009.jpg",
    thumb: "/static/gallery/thumbs/img009.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img015.jpg",
    thumb: "/static/gallery/thumbs/img015.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img016.jpg",
    thumb: "/static/gallery/thumbs/img016.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img017.jpg",
    thumb: "/static/gallery/thumbs/img017.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img018.jpg",
    thumb: "/static/gallery/thumbs/img018.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img020.jpg",
    thumb: "/static/gallery/thumbs/img020.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img022.jpg",
    thumb: "/static/gallery/thumbs/img022.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img023.jpg",
    thumb: "/static/gallery/thumbs/img023.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img025.jpg",
    thumb: "/static/gallery/thumbs/img025.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img030.jpg",
    thumb: "/static/gallery/thumbs/img030.jpg",
    width: 5,
    height: 3,
  },
  {
    image: "/static/gallery/images/img031.jpg",
    thumb: "/static/gallery/thumbs/img031.jpg",
    width: 5,
    height: 3,
  },
  {
    image: "/static/gallery/images/img033.jpg",
    thumb: "/static/gallery/thumbs/img033.jpg",
    width: 3,
    height: 2,
  },
  {
    image: "/static/gallery/images/img036.jpg",
    thumb: "/static/gallery/thumbs/img036.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img037.jpg",
    thumb: "/static/gallery/thumbs/img037.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img040.jpg",
    thumb: "/static/gallery/thumbs/img040.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img041.jpg",
    thumb: "/static/gallery/thumbs/img041.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img042.jpg",
    thumb: "/static/gallery/thumbs/img042.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img047.jpg",
    thumb: "/static/gallery/thumbs/img047.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img048.jpg",
    thumb: "/static/gallery/thumbs/img048.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img049.jpg",
    thumb: "/static/gallery/thumbs/img049.jpg",
    width: 3,
    height: 3,
  },
  {
    image: "/static/gallery/images/img050.jpg",
    thumb: "/static/gallery/thumbs/img050.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img052.jpg",
    thumb: "/static/gallery/thumbs/img052.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img053.jpg",
    thumb: "/static/gallery/thumbs/img053.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img054.jpg",
    thumb: "/static/gallery/thumbs/img054.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img055.jpg",
    thumb: "/static/gallery/thumbs/img055.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img056.jpg",
    thumb: "/static/gallery/thumbs/img056.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img057.jpg",
    thumb: "/static/gallery/thumbs/img057.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img058.jpg",
    thumb: "/static/gallery/thumbs/img058.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img059.jpg",
    thumb: "/static/gallery/thumbs/img059.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img060.jpg",
    thumb: "/static/gallery/thumbs/img060.jpg",
    width: 3,
    height: 3,
  },
  {
    image: "/static/gallery/images/img061.jpg",
    thumb: "/static/gallery/thumbs/img061.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img062.jpg",
    thumb: "/static/gallery/thumbs/img062.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img063.jpg",
    thumb: "/static/gallery/thumbs/img063.jpg",
    width: 2,
    height: 4,
  },
  {
    image: "/static/gallery/images/img064.jpg",
    thumb: "/static/gallery/thumbs/img064.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img065.jpg",
    thumb: "/static/gallery/thumbs/img065.jpg",
    width: 4,
    height: 3,
  },
  {
    image: "/static/gallery/images/img066.jpg",
    thumb: "/static/gallery/thumbs/img066.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img067.jpg",
    thumb: "/static/gallery/thumbs/img067.jpg",
    width: 2,
    height: 4,
  },
  {
    image: "/static/gallery/images/img068.jpg",
    thumb: "/static/gallery/thumbs/img068.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img069.jpg",
    thumb: "/static/gallery/thumbs/img069.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img070.jpg",
    thumb: "/static/gallery/thumbs/img070.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img071.jpg",
    thumb: "/static/gallery/thumbs/img071.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img072.jpg",
    thumb: "/static/gallery/thumbs/img072.jpg",
    width: 3,
    height: 4,
  },
  {
    image: "/static/gallery/images/img073.jpg",
    thumb: "/static/gallery/thumbs/img073.jpg",
    width: 2,
    height: 4,
  },
  {
    image: "/static/gallery/images/img075.jpg",
    thumb: "/static/gallery/thumbs/img075.jpg",
    width: 2,
    height: 4,
  },
  {
    image: "/static/gallery/images/img076.jpg",
    thumb: "/static/gallery/thumbs/img076.jpg",
    width: 2,
    height: 4,
  },
  {
    image: "/static/gallery/images/img077.jpg",
    thumb: "/static/gallery/thumbs/img077.jpg",
    width: 5,
    height: 6,
  },
  {
    image: "/static/gallery/images/img078.jpg",
    thumb: "/static/gallery/thumbs/img078.jpg",
    width: 1024,
    height: 575,
  },
  {
    image: "/static/gallery/images/img079.jpg",
    thumb: "/static/gallery/thumbs/img079.jpg",
    width: 1024,
    height: 575,
  },
  {
    image: "/static/gallery/images/img080.jpg",
    thumb: "/static/gallery/thumbs/img080.jpg",
    width: 768,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img081.jpg",
    thumb: "/static/gallery/thumbs/img081.jpg",
    width: 768,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img082.jpg",
    thumb: "/static/gallery/thumbs/img082.jpg",
    width: 768,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img083.jpg",
    thumb: "/static/gallery/thumbs/img083.jpg",
    width: 708,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img084.jpg",
    thumb: "/static/gallery/thumbs/img084.jpg",
    width: 740,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img085.jpg",
    thumb: "/static/gallery/thumbs/img085.jpg",
    width: 737,
    height: 1024,
  },
  {
    image: "/static/gallery/images/img086.jpg",
    thumb: "/static/gallery/thumbs/img086.jpg",
    width: 780,
    height: 1040,
  },
  {
    image: "/static/gallery/images/img087.jpg",
    thumb: "/static/gallery/thumbs/img087.jpg",
    width: 780,
    height: 1040,
  },
  {
    image: "/static/gallery/images/img088.jpg",
    thumb: "/static/gallery/thumbs/img088.jpg",
    width: 780,
    height: 1040,
  },
  {
    image: "/static/gallery/images/img089.jpg",
    thumb: "/static/gallery/thumbs/img089.jpg",
    width: 1040,
    height: 780,
  },
  {
    image: "/static/gallery/images/img090.jpg",
    thumb: "/static/gallery/thumbs/img090.jpg",
    width: 1920,
    height: 2560,
  },
].reverse();
