import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import useTitle from "../../hooks/useTitle";
import Video from "./Video";

const Videos = () => {
  const title = useTitle({
    defaultMessage: "Videos",
    id: "menu.videos",
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>
            <FormattedMessage defaultMessage="Videos" id="videos" />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2023-06-08")}
            id="RW4XN4soghA"
            title="NANGA PARBAT 2023"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2022-08-25")}
            id="ybX-bvwoua4"
            title="KARAKORUM 2022 BALTORO GASHERBRUM II BASE CAMP"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2022-03-05")}
            id="6EXSpQGD6NU"
            title="EIGER 05 03 2022 Switzerland"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2021-05-29")}
            id="QlOU_S2JAHg"
            title="EIGER 29.05.2021 Switzerland"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2020-02-10")}
            id="XYJoLe63G_c"
            title="HIMALAJE 2020 Everest Base Camp (5364 m.) KalaPattar (5643 m.) Luty - February 2020"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2019-09-15")}
            id="ZwoJssT3Wto"
            title="GROSSGLOCKNER 3798 m AUSTRIA 2019"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2019-05-06")}
            id="_ftV0eebuuI"
            title="DUFOURSPITZE 4634m 2019 Monte Rosa Szwajcaria"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2019-01-26")}
            id="kR6Dbb3oHwg"
            title="Zumsteinspitze 4563m n.p.m. 2019 Alpy Monte Rosa"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2018-08-26")}
            id="nf5l8j2e_Fw"
            title="BREITHORN 2018 Szwajcaria (4200 m.n.p.m.)"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2018-03-17")}
            id="QIC-an0FmJs"
            title="HIMALAJE Pisang Peak 2018 (6100 m n.p.m.)"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2017-08-31")}
            id="xTCka_6k9o4"
            title="MATTERHORN 2017 (4478 m n.p.m.)"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2016-08-17")}
            id="yIsCbgDJEHQ"
            title="LENIN PEAK 2016 (7134m)"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2015-09-04")}
            id="Bn6gh7DL3tg"
            title="Tadżykistan 2015 Base Camp Pik Korżeniewskiej, Komunizma"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2014-10-19")}
            id="jmJX8D7bKFU"
            title="MONT BLANC 2014 (4810 m n.p.m.)"
          />
        </Col>
        <Col md={6} lg={4}>
          <Video
            date={new Date("2014-04-14")}
            id="hjcumL6k3wk"
            title="MONT BLANC KWIECIEŃ 2014...PRAWIE:-) C.D.N"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Videos;
