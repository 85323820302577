import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const LinkToService = () => (
  <Link to="/service">
    <FormattedMessage defaultMessage="offer" id="about.offer_link" />
  </Link>
);

export default LinkToService;
