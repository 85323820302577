import React from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Menu from "./Menu";
import Toggle from "./Toggle";
import setLocaleAction from "../../actions/setLocale";
import selectLocale from "../../selectors/selectLocale";
import supportedLocales from "../../utils/supportedLocales";

interface LanguageProps {
  className?: string;
}

const Language = ({ className }: LanguageProps) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);

  const setLocale = (locale: string) => {
    dispatch(setLocaleAction(locale));
  };

  return (
    <div className={className}>
      <Dropdown>
        <Dropdown.Toggle as={Toggle} id="language">
          <FormattedMessage defaultMessage={locale} id={`locale.${locale}`} />
          <img alt={locale} src={`static/images/locale-${locale}.png`} />
        </Dropdown.Toggle>
        <Dropdown.Menu as={Menu}>
          {supportedLocales.map(supportedLocale => (
            <Dropdown.Item
              active={supportedLocale === locale}
              key={supportedLocale}
              onClick={() => {
                setLocale(supportedLocale);
              }}
            >
              <FormattedMessage
                defaultMessage={supportedLocale}
                id={`locale.${supportedLocale}`}
              />
              <img
                alt={locale}
                src={`static/images/locale-${supportedLocale}.png`}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const StyledLanguage = styled(Language)`
  img {
    border-radius: 10%;
    height: 20px;
    margin: 0 12px;
  }
`;

export default StyledLanguage;
