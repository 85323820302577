import React from "react";
import { Navbar, Container, Col, Nav, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import styled from "styled-components";

import Language from "../../modules/settings/components/Language";

interface MenuProps {
  className?: string;
}

const Menu = withRouter(
  ({ className, location }: MenuProps & RouteComponentProps) => {
    const matchAbout = matchPath(location.pathname, {
      path: "/about"
    });

    const matchService = matchPath(location.pathname, {
      path: "/service"
    });

    const matchGallery = matchPath(location.pathname, {
      path: "/gallery"
    });

    const matchVideos = matchPath(location.pathname, {
      path: "/videos"
    });

    return (
      <Navbar bg="light" className={className} expand="lg">
        <Container>
          <Row>
            <Col className="navbar-container">
              <Navbar.Brand href="#/">
                <img
                  alt="logo"
                  className="logo-image"
                  src="static/images/alpinjob.png"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-main" />
              <Navbar.Collapse id="navbar-main">
                <Nav className="nav-main">
                  <Nav.Link
                    active={!!matchAbout && matchAbout.isExact}
                    href="#/about"
                  >
                    <FormattedMessage defaultMessage="About" id="menu.about" />
                  </Nav.Link>
                  <Nav.Link
                    active={!!matchService && matchService.isExact}
                    href="#/service"
                  >
                    <FormattedMessage
                      defaultMessage="Service"
                      id="menu.service"
                    />
                  </Nav.Link>
                  <Nav.Link
                    active={!!matchGallery && matchGallery.isExact}
                    href="#/gallery"
                  >
                    <FormattedMessage
                      defaultMessage="Gallery"
                      id="menu.photos"
                    />
                  </Nav.Link>
                  <Nav.Link
                    active={!!matchVideos && matchVideos.isExact}
                    href="#/videos"
                  >
                    <FormattedMessage
                      defaultMessage="Videos"
                      id="menu.videos"
                    />
                  </Nav.Link>
                </Nav>
                <Nav className="nav-language">
                  <Language />
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
);

const StyledMenu = styled(Menu)`
  .row {
    width: 100%;

    .navbar-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .navbar-brand {
        img {
          height: 6rem;
        }
      }

      .nav-main {
        flex-grow: 1;
        justify-content: center;

        .nav-link {
          font-size: 14pt;
          font-weight: 700;
          margin: 8px 16px;

          &.active {
            border-bottom: solid 3px var(--accent-500-color);
          }
        }
      }

      .nav-social-media {
        .nav-link {
          font-size: 20pt;

          &:hover {
            color: var(--accent-500-color);
          }
        }
      }
    }
  }
`;

export default StyledMenu;
