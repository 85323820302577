import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import Contact from "./Contact";
import Content from "./Content";

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => (
  <div className={className}>
    <Container>
      <Row>
        <Col md={4}>
          <Content />
        </Col>
        <Col md={4}>
          <Contact />
        </Col>
      </Row>
    </Container>
  </div>
);

const StyledFooter = styled(Footer)`
  background: var(--gray-900-color);
  color: white;
  margin-top: 48px;
  padding: 48px 0;

  h5 {
    color: var(--gray-400-color);
    font-weight: bold;
  }

  ul {
    margin-top: 24px;
    padding: 0;

    li,
    li a {
      color: var(--gray-400-color);
      font-size: 12pt;
    }

    li a {
      &:hover {
        color: var(--accent-200-color);
      }
    }
  }
`;

export default StyledFooter;
