import React from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import selectLocale from "../modules/settings/selectors/selectLocale";

const messages = require("../messages.json");

export interface IntlProviderProps extends React.Props<{}> {}

const IntlProvider = ({ children }: IntlProviderProps) => {
  const locale = useSelector(selectLocale);

  return (
    <ReactIntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
