import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

interface HeaderProps {
  className?: string;
}

const Header = withRouter(
  ({ className }: HeaderProps & RouteComponentProps) => {
    const handleButtonClick = () => {
      window.scrollTo(0, document.body.scrollHeight);
    };

    return (
      <div className={className}>
        <div className="image" />
        <div className="shadow" />
        <div className="content">
          <Container>
            <Row>
              <Col>
                <p className="text">
                  <FormattedMessage
                    defaultMessage="Alpinistyczne prace na wysokościach"
                    id="header.alpinjob"
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={handleButtonClick} variant="light">
                  <FormattedMessage
                    defaultMessage="Contact me"
                    id="header.contact"
                  />
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
);

const StyledHeader = styled(Header)`
  height: 40vh;
  overflow: hidden;
  position: relative;

  @media (max-height: 768px) {
    height: 50vh;
  }

  .image,
  .shadow,
  .content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .image {
    background-image: url(static/images/banner.jpg);
    background-position: left center;
    background-size: cover;

    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  .shadow {
    background: rgba(128, 128, 128, 0.25);
  }

  .content {
    display: flex;
    align-items: center;
    font-family: "Changa", sans-serif;

    .text {
      color: white;
      font-size: 42pt;
      font-weight: 500;
      line-height: 1.2;
      max-width: 25ch;
    }

    button {
      background-color: transparent;
      border-color: white;
      color: white;
      margin-top: 32px;

      &:hover {
        border-color: var(--accent-200-color);
        color: var(--accent-200-color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        background-color: transparent;
        border-color: var(--accent-300-color);
        color: var(--accent-300-color);
      }
    }
  }
`;

export default StyledHeader;
