import { AnyAction } from "redux";

export const actionType = "Configuration_Locale_SetLocale";

export interface Action extends AnyAction {
  locale: string;
}

const setLocale = (locale: string): Action => ({ type: actionType, locale });

export default setLocale;
