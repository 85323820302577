import React, { MouseEvent, forwardRef, Props } from "react";
import styled from "styled-components";

interface ToggleProps extends Props<{}> {
  className?: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const Toggle = forwardRef(
  ({ children, className, onClick }: ToggleProps, ref) => {
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <div className={className} onClick={handleClick}>
        {children}
      </div>
    );
  }
);

const StyledToggle = styled(Toggle)`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);

  :hover {
    color: rgba(0, 0, 0, 0.7);
  }
`;

export default StyledToggle;
